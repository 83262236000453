import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarMessageErrorComponent } from '../conmponents/snack-bar-message-error/snack-bar-message-error.component';
import { SnackBarMessageSuccessComponent } from '../conmponents/snack-bar-message-success/snack-bar-message-success.component';
import { SnackBarMessageWarningComponent } from '../conmponents/snack-bar-message-warning/snack-bar-message-warning.component';

@Injectable()
export class InfoMessagesService {
  constructor(private readonly snackBar: MatSnackBar) {}

  public showSuccess(message: string) {
    this.snackBar.openFromComponent(SnackBarMessageSuccessComponent, {
      panelClass: ['snack-bar-action-message', 'info-success-panel'],
      data: message,
    });
  }

  public showWarning(message: string) {
    this.snackBar.openFromComponent(SnackBarMessageWarningComponent, {
      panelClass: ['snack-bar-action-message', 'info-warning-panel'],
      data: message,
    });
  }

  public showError(message: string) {
    this.snackBar.openFromComponent(SnackBarMessageErrorComponent, {
      panelClass: ['snack-bar-action-message', 'info-error-panel'],
      data: message,
    });
  }
}
