import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { SharedModule } from '@shared/shared.module';
import { SnackBarMessageErrorComponent } from './conmponents/snack-bar-message-error/snack-bar-message-error.component';
import { SnackBarMessageSuccessComponent } from './conmponents/snack-bar-message-success/snack-bar-message-success.component';
import { SnackBarMessageWarningComponent } from './conmponents/snack-bar-message-warning/snack-bar-message-warning.component';
import { InfoMessagesService } from './services/info-messages.service';

@NgModule({
  declarations: [SnackBarMessageSuccessComponent, SnackBarMessageErrorComponent, SnackBarMessageWarningComponent],
  providers: [
    InfoMessagesService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 10000, panelClass: 'snack-bar-action-message' } },
  ],
  imports: [CommonModule, SharedModule],
})
export class InfoMessagesModule {}
